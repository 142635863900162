const swiper = new Swiper('.services__carousel', {
    wrapperClass: 'services__ribbon',
    slideClass: 'services__card',
    direction: 'horizontal',
    loop: true,
    speed: 1000,
    spaceBetween: 15,
    autoplay: {
      delay: 4000,
    }
});